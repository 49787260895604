// If you want to override variables do it here
@import "variables";

$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
@import "@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

@import "layout";
@import "example";

// If you want to add custom CSS you can put it here.
@import "custom";

input {
  padding: 7px !important;
  border-radius: 3px;
  height: 15px !important;
}

.form-control {
  // height: 15px !important;
  padding: 17px 12px !important;
}

textarea.form-control {
  padding: 7px !important;
}

.width-40 {
  width: 80px;
}

.no-border-radius, .no-radius {
  border-radius: 0px;
}

.font-12 {
  font-size: 12px !important;
}
.font-14 {
  font-size: 14px !important;
}
.font-16 {
  font-size: 16px !important;
}
.font-18 {
  font-size: 18px !important;
}
.font-20 {
  font-size: 20px !important;
}

.divine-CFormLabel {
  font-size: 12px;
  margin: 0px;
  padding: 0px !important;
}

.font-weight-bold {
  font-weight: bold;
}

.font-weight-500 {
  font-weight: 500;
}

.cursor-pointer {
  cursor: pointer;
}

.no-background {
  padding: 0px;
  background: transparent;
  border: 0px;
  margin: 0px;
  outline: 0px !important;
  border-radius: 3px !important;
  box-shadow: none !important;
  &:hover, &:focus, &:active {
    padding: 0px;
    background: transparent;
    border: 0px;
    margin: 0px;
    box-shadow: none !important;
  }
}

.divine-date-time-picker {
  padding: 0px;
  width: 100%;
  input {
    height: 23px !important;
  }
  fieldset {
    top: 0px !important;
    border-radius: 3px;
  }
}


.page-back-button {
  position: relative;
  .page-back-button-label {
    padding: 5px 10px;
    background: black;
    color: white;
    cursor: pointer;
  }
  &::before {
    content: '';

    position: absolute;
    top: 50%;
    width: calc(100% - 70px);
    right: 0px;
    height: 1px;
    background: lightgray;
  }
}

.table-header-btn {
  background: #eae6e6;
  margin-right: 8px;
  padding: 4px 10px;
  display: flex;
  // justify-content: space-between;
  align-items: center;
  border-radius: 2px;
  box-shadow: 0px 0px 0.5px gray;
  border: 0.5px solid gray;
  color: black;
  &:active, &:hover, &:focus {
    outline: 0 !important;
    border-radius: 2px !important;
    box-shadow: 0px 0px 0.5px gray !important;
    border: 0.5px solid gray !important;
    background: #eae6e6 !important;
    color: black !important;
  }
}

.divine-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.background-white {
  background: white !important;
}

.file-attachment {
  display: none !important;
}

.page-heading {
  font-size: 18px;
  font-weight: 500;
}

.color-black {
  color: black !important;
}

.divine-unauth-link {
  text-decoration: none;
  color: white;
}

.no-notifications {
  height: 300px;
  // display:
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notifications-indicator {
  width: 10px;
  height: 10px;
  background: green;
  position: absolute;
  top: 5%;
  right: 25%;
  border-radius: 10px;
}

.dropdown-item.active, .dropdown-item:active {
  color: var(--cui-dropdown-link-hover-color);
  background-color: var(--cui-dropdown-link-hover-bg);
}

.padding-8 {
  padding: 8px;
}

.notification-content {
  display: flex;

}

.top-8 {
  margin-top: 8px;
}

.date-range-filter {
  background: white;
  
}

.AZtYZ_gqcAOLDf7ddydv {
  filter: blur(2) !important;
}